import React, { Fragment, useRef, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

const MessageBox = ({ status, content, show, btn_event }) => {

 
    if (show) {
        return (
            <div className="msg_box" style={{ position: 'absolute', zIndex: '20', width: '400px', margin: 'auto', height: '250px', background: '#ffffff', borderRadius: '8px', border: '3px solid orange', top: '100px', left: '0', margin: 'auto', right: '0', padding: '30px', boxShadow: '0 0 10px #00000069' }}>
                <div className="mb-4" >

                    {
                        status && <div
                            style={{ color: 'orange', textTransform: 'capitalize',marginBottom:'30px',textAlign:'center' }}
                        >
                            {status}
                        </div>
                    }
                    {
                        content && <div
                            claccName='text-center mb-4 mt-4'
                        >
                            {content}
                        </div>
                    }
                    
                        <Link to="/"
                        style={{margin:'30px  auto 0'}}
                        className='2xl:col-span-6  disabled:bg-orange-300 transitions hover:bg-subMain bg-main py-3 px-4 rounded-md flex-colo font-semibold text-white'
                   >
                            Done
                        </Link>
                   


                </div>
            </div>

        );
    }



};

export default React.memo(MessageBox);
