export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGIN_RESET = 'USER_LOGIN_RESET';
export const USER_LOGOUT = 'USER_LOGOUT';

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';
export const USER_REGISTER_RESET = 'USER_REGISTER_RESET';

export const PROFILE_UPDATE_REQUEST = 'PROFILE_UPDATE_REQUEST';
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const PROFILE_UPDATE_FAIL = 'PROFILE_UPDATE_FAIL';
export const PROFILE_UPDATE_RESET = 'PROFILE_UPDATE_RESET';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';
export const CHANGE_PASSWORD_RESET = 'CHANGE_PASSWORD_RESET';

export const DELETE_PROFILE_REQUEST = 'DELETE_PROFILE_REQUEST';
export const DELETE_PROFILE_SUCCESS = 'DELETE_PROFILE_SUCCESS';
export const DELETE_PROFILE_FAIL = 'DELETE_PROFILE_FAIL';
export const DELETE_PROFILE_RESET = 'DELETE_PROFILE_RESET';

// CATEGORY CONSTANTS

export const CATEGORY_LIST_REQUEST = 'CATEGORY_LIST_REQUEST';
export const CATEGORY_LIST_SUCCESS = 'CATEGORY_LIST_SUCCESS';
export const CATEGORY_LIST_FAIL = 'CATEGORY_LIST_FAIL';
export const CATEGORY_LIST_RESET = 'CATEGORY_LIST_RESET';

// CARD CONSTANTS

export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST';
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS';
export const PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL';
export const PRODUCT_LIST_RESET = 'PRODUCT_LIST_RESET';

export const GET_PRODUCT_REQUEST = 'GET_PRODUCT_REQUEST';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAIL = 'GET_PRODUCT_FAIL';
export const GET_PRODUCT_RESET = 'GET_PRODUCT_RESET';

export const TAGS_PRODUCT = 'TAGS_PRODUCT';
export const TAGS_PRODUCT_FAIL = 'TAGS_PRODUCT_FAIL';
export const TAGS_PRODUCT_RESET = 'TAGS_PRODUCT_RESET';

// ORDER CONSTANTS

export const ORDER_LIST_REQUEST = 'ORDER_LIST_REQUEST';
export const ORDER_LIST_SUCCESS = 'ORDER_LIST_SUCCESS';
export const ORDER_LIST_FAIL = 'ORDER_LIST_FAIL';
export const ORDER_LIST_RESET = 'ORDER_LIST_RESET';

export const GET_ORDER_REQUEST = 'GET_ORDER_REQUEST';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAIL = 'GET_ORDER_FAIL';
export const GET_ORDER_RESET = 'GET_ORDER_RESET';

export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST';
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS';
export const ORDER_CREATE_FAIL = 'ORDER_CREATE_FAIL';
export const ORDER_CREATE_RESET = 'ORDER_CREATE_RESET';

export const ORDER_DELETE = 'ORDER_DELETE';
export const ORDER_DELETE_FAIL = 'ORDER_DELETE_FAIL';
export const ORDER_DELETE_RESET = 'ORDER_DELETE_RESET';

export const DELETE_ALL_ORDERS_REQUEST = 'DELETE_ALL_ORDERS_REQUEST';
export const DELETE_ALL_ORDERS_SUCCESS = 'DELETE_ALL_ORDERS_SUCCESS';
export const DELETE_ALL_ORDERS_FAIL = 'DELETE_ALL_ORDERS_FAIL';
export const DELETE_ALL_ORDERS_RESET = 'DELETE_ALL_ORDERS_RESET';

export const STRIPE = 'STRIPE';
export const STRIPE_ERROR = 'STRIPE_ERROR';
export const STRIPE_RESET = 'STRIPE_RESET';

// wishlist CONSTANTS
export const ADD_WISHLIST = 'ADD_WISHLIST';
export const DELETE_ALL_WISHLIST = 'DELETE_ALL_WISHLIST';
export const DELETE_WISHLIST = 'DELETE_WISHLIST';
