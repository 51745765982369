import React from 'react';
import Layout from '../layout/Layout';

function OrderSuccess() {
  return (
    <Layout header={true}>
      <p>Order Success</p>
    </Layout>
  );
}

export default OrderSuccess;
